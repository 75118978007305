.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  box-sizing: border-box;
  display: inline-block;
  background-color: transparent;
  padding: 7px 20px;
  border-radius: 0;
  border: 1px solid #5b6069;
  color: #5b6069;
  font-size: 17px;
  line-height: 1.41176471;
  letter-spacing: 0.03em;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), border 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), color 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #5b6069;
}
.button:active {
  color: #fff;
  background-color: #5b6069;
}
.button-invers {
  box-sizing: border-box;
  display: inline-block;
  padding: 7px 20px;
  border-radius: 0;
  border: 1px solid #5b6069;
  color: #5b6069;
  font-size: 17px;
  line-height: 1.41176471;
  letter-spacing: 0.03em;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), border 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), color 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button-invers:hover,
.button-invers:focus {
  color: #fff;
  background-color: #5b6069;
}
.button-invers:active {
  color: #fff;
  background-color: #5b6069;
}
.button-invers:hover,
.button-invers:focus {
  color: #5b6069;
  background-color: #fff;
}
.button-invers:active {
  color: #5b6069;
  background-color: #fff;
}
.desk {
  max-width: calc(100% - 30px);
}
@media only screen and (max-width: 1199px) {
  .desk {
    width: 1020px;
  }
}
.desk.desk--large {
  max-width: 100%;
}
#head {
  width: 50%;
  margin-top: 50px;
}
.cb-layout1 #head {
  width: 100%;
  margin-top: 80px;
}
.section--footer .footer-content--bottom {
  flex-direction: row;
  align-items: center;
}
.section--footer .vcard,
.section--footer .copy,
.section--footer #social {
  width: 33.33333333%;
}
.section--footer #services,
.section--footer #social {
  width: 66.66666667%;
}
.section--footer #social {
  margin-left: 33.33333333%;
  margin-top: 24px;
}
.section--footer .vcard {
  float: left;
}
.section--footer div.navi {
  display: block;
}
.section--footer div.navi.sub1 {
  width: 66.66666667%;
  column-count: 2;
  column-gap: 30px;
  columns: 2 220px;
}
.section--footer div.navi.sub1 > .item {
  width: 100%;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  padding: 0 0 24px;
}
.section--footer div.navi.sub1 > .item > .menu {
  display: block;
  padding: 0 0 9px;
  font-size: 16px;
  line-height: 1.375;
  font-weight: 600;
  letter-spacing: 0.05em;
}
.section--footer div.navi.sub2 {
  width: 100%;
}
.section--footer div.navi.sub2 > .item {
  width: 100%;
}
.section--footer div.navi.sub2 > .item > .menu {
  display: block;
  padding: 9px 0;
}
@media (max-width: 1199px) {
  h1 {
    font-size: 32px;
  }
}
.area.base {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: calc(100% + 12px);
  margin-left: -6px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.area.base .unit {
  width: calc(50% - 12px);
  margin-left: 6px;
  margin-right: 6px;
  padding: 30px;
}
.area.base .unit .body {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;
}
.area.base .unit .body .part.link:last-child {
  padding-top: 11px;
  margin-top: auto;
}
#view .base .unit {
  padding-bottom: 61px;
}
#view .base .unit .part.link a.open {
  padding-bottom: 30px;
  padding-left: 50px;
  background-position: left 30px bottom 34px;
}
.cb-layout2 .area.base .unit,
.cb-layout3 .area.base .unit {
  width: calc(100% / 3 - 12px);
}
.area.farnorth {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.area.farnorth .unit {
  width: 50%;
}
.area.farnorth .unit .body {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;
}
.area.farnorth .unit.seam .body .part.link:last-child,
.area.farnorth .unit.flat .body .part.link:last-child,
.area.farnorth .unit.edge .body .part.link:last-child {
  padding-top: 15px;
  margin-top: auto;
}
.container--north {
  margin-top: 30px;
}
.area.north {
  margin-top: 30px;
  margin-bottom: 30px;
}
.cb-layout1 .container--main {
  margin-top: 30px;
}
.cb-layout1 .container--main .left {
  width: 50%;
  padding-right: 20px;
}
.cb-layout1 .container--main .right {
  float: right;
  width: 50%;
}
.main .unit:first-child {
  margin-top: 0;
}
.cb-layout1 .area.farwest,
.cb-layout1 .area.fareast,
.cb-layout2 .area.east {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.cb-layout1 .area.farwest .unit.slim,
.cb-layout1 .area.fareast .unit.slim,
.cb-layout2 .area.east .unit.slim {
  width: 50%;
}
.cb-layout1 .area.farwest .unit .part,
.cb-layout1 .area.fareast .unit .part,
.cb-layout2 .area.east .unit .part,
.cb-layout1 .area.farwest .unit .foot,
.cb-layout1 .area.fareast .unit .foot,
.cb-layout2 .area.east .unit .foot {
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}
.cb-layout1 .area.farwest .unit .part.tiny,
.cb-layout1 .area.fareast .unit .part.tiny,
.cb-layout2 .area.east .unit .part.tiny,
.cb-layout1 .area.farwest .unit .foot.tiny,
.cb-layout1 .area.fareast .unit .foot.tiny,
.cb-layout2 .area.east .unit .foot.tiny {
  width: calc(50% - 30px);
}
.cb-layout1 .area.farwest .unit.slim:nth-child(2n + 2),
.cb-layout1 .area.fareast .unit.slim:nth-child(2n + 2),
.cb-layout1 .area.north .unit.slim:nth-child(2n + 2),
.cb-layout2 .area.east .unit.slim:nth-child(2n + 2) {
  margin-top: 230px;
}
.cb-layout2 .area.main,
.cb-layout3 .area.main,
.cb-layout2 .area.farwest,
.cb-layout2 .fareast,
.cb-layout2 .area.north {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.cb-layout2 .area.main .unit.slim,
.cb-layout3 .area.main .unit.slim,
.cb-layout2 .area.farwest .unit.slim,
.cb-layout2 .fareast .unit.slim,
.cb-layout2 .area.north .unit.slim {
  width: 50%;
}
.cb-layout2 .area.main .unit .part,
.cb-layout3 .area.main .unit .part,
.cb-layout2 .area.farwest .unit .part,
.cb-layout2 .fareast .unit .part,
.cb-layout2 .area.north .unit .part,
.cb-layout2 .area.main .unit .foot,
.cb-layout3 .area.main .unit .foot,
.cb-layout2 .area.farwest .unit .foot,
.cb-layout2 .fareast .unit .foot,
.cb-layout2 .area.north .unit .foot {
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}
.cb-layout2 .area.main .unit .part.tiny,
.cb-layout3 .area.main .unit .part.tiny,
.cb-layout2 .area.farwest .unit .part.tiny,
.cb-layout2 .fareast .unit .part.tiny,
.cb-layout2 .area.north .unit .part.tiny,
.cb-layout2 .area.main .unit .foot.tiny,
.cb-layout3 .area.main .unit .foot.tiny,
.cb-layout2 .area.farwest .unit .foot.tiny,
.cb-layout2 .fareast .unit .foot.tiny,
.cb-layout2 .area.north .unit .foot.tiny {
  width: calc(50% - 30px);
}
.cb-layout2 .area.main .unit.seam,
.cb-layout3 .area.main .unit.seam,
.cb-layout2 .area.farwest .unit.seam,
.cb-layout2 .fareast .unit.seam,
.cb-layout2 .area.north .unit.seam,
.cb-layout2 .area.main .unit.flat,
.cb-layout3 .area.main .unit.flat,
.cb-layout2 .area.farwest .unit.flat,
.cb-layout2 .fareast .unit.flat,
.cb-layout2 .area.north .unit.flat,
.cb-layout2 .area.main .unit.edge,
.cb-layout3 .area.main .unit.edge,
.cb-layout2 .area.farwest .unit.edge,
.cb-layout2 .fareast .unit.edge,
.cb-layout2 .area.north .unit.edge {
  margin-top: 30px;
  margin-bottom: 30px;
}
.cb-layout2 .area.main .unit.seam .part,
.cb-layout3 .area.main .unit.seam .part,
.cb-layout2 .area.farwest .unit.seam .part,
.cb-layout2 .fareast .unit.seam .part,
.cb-layout2 .area.north .unit.seam .part,
.cb-layout2 .area.main .unit.flat .part,
.cb-layout3 .area.main .unit.flat .part,
.cb-layout2 .area.farwest .unit.flat .part,
.cb-layout2 .fareast .unit.flat .part,
.cb-layout2 .area.north .unit.flat .part,
.cb-layout2 .area.main .unit.edge .part,
.cb-layout3 .area.main .unit.edge .part,
.cb-layout2 .area.farwest .unit.edge .part,
.cb-layout2 .fareast .unit.edge .part,
.cb-layout2 .area.north .unit.edge .part {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
}
.cb-layout2 .area.main .unit.seam .part.pict,
.cb-layout3 .area.main .unit.seam .part.pict,
.cb-layout2 .area.farwest .unit.seam .part.pict,
.cb-layout2 .fareast .unit.seam .part.pict,
.cb-layout2 .area.north .unit.seam .part.pict,
.cb-layout2 .area.main .unit.flat .part.pict,
.cb-layout3 .area.main .unit.flat .part.pict,
.cb-layout2 .area.farwest .unit.flat .part.pict,
.cb-layout2 .fareast .unit.flat .part.pict,
.cb-layout2 .area.north .unit.flat .part.pict,
.cb-layout2 .area.main .unit.edge .part.pict,
.cb-layout3 .area.main .unit.edge .part.pict,
.cb-layout2 .area.farwest .unit.edge .part.pict,
.cb-layout2 .fareast .unit.edge .part.pict,
.cb-layout2 .area.north .unit.edge .part.pict {
  width: 100%;
  margin: 0;
}
.cb-layout2 .area.main .unit.seam .part.tiny,
.cb-layout3 .area.main .unit.seam .part.tiny,
.cb-layout2 .area.farwest .unit.seam .part.tiny,
.cb-layout2 .fareast .unit.seam .part.tiny,
.cb-layout2 .area.north .unit.seam .part.tiny,
.cb-layout2 .area.main .unit.flat .part.tiny,
.cb-layout3 .area.main .unit.flat .part.tiny,
.cb-layout2 .area.farwest .unit.flat .part.tiny,
.cb-layout2 .fareast .unit.flat .part.tiny,
.cb-layout2 .area.north .unit.flat .part.tiny,
.cb-layout2 .area.main .unit.edge .part.tiny,
.cb-layout3 .area.main .unit.edge .part.tiny,
.cb-layout2 .area.farwest .unit.edge .part.tiny,
.cb-layout2 .fareast .unit.edge .part.tiny,
.cb-layout2 .area.north .unit.edge .part.tiny {
  width: calc(50% - 40px);
}
.cb-layout2 .area.main .unit.seam .part.tiny.pict,
.cb-layout3 .area.main .unit.seam .part.tiny.pict,
.cb-layout2 .area.farwest .unit.seam .part.tiny.pict,
.cb-layout2 .fareast .unit.seam .part.tiny.pict,
.cb-layout2 .area.north .unit.seam .part.tiny.pict,
.cb-layout2 .area.main .unit.flat .part.tiny.pict,
.cb-layout3 .area.main .unit.flat .part.tiny.pict,
.cb-layout2 .area.farwest .unit.flat .part.tiny.pict,
.cb-layout2 .fareast .unit.flat .part.tiny.pict,
.cb-layout2 .area.north .unit.flat .part.tiny.pict,
.cb-layout2 .area.main .unit.edge .part.tiny.pict,
.cb-layout3 .area.main .unit.edge .part.tiny.pict,
.cb-layout2 .area.farwest .unit.edge .part.tiny.pict,
.cb-layout2 .fareast .unit.edge .part.tiny.pict,
.cb-layout2 .area.north .unit.edge .part.tiny.pict {
  width: 50%;
  margin: 0;
}
@media (max-width: 1199px) {
  .cb-layout2 .area.main .unit.seam .part,
  .cb-layout3 .area.main .unit.seam .part,
  .cb-layout2 .area.farwest .unit.seam .part,
  .cb-layout2 .fareast .unit.seam .part,
  .cb-layout2 .area.north .unit.seam .part,
  .cb-layout2 .area.main .unit.flat .part,
  .cb-layout3 .area.main .unit.flat .part,
  .cb-layout2 .area.farwest .unit.flat .part,
  .cb-layout2 .fareast .unit.flat .part,
  .cb-layout2 .area.north .unit.flat .part,
  .cb-layout2 .area.main .unit.edge .part,
  .cb-layout3 .area.main .unit.edge .part,
  .cb-layout2 .area.farwest .unit.edge .part,
  .cb-layout2 .fareast .unit.edge .part,
  .cb-layout2 .area.north .unit.edge .part {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .cb-layout2 .area.main .unit.seam .part,
  .cb-layout3 .area.main .unit.seam .part,
  .cb-layout2 .area.farwest .unit.seam .part,
  .cb-layout2 .fareast .unit.seam .part,
  .cb-layout2 .area.north .unit.seam .part,
  .cb-layout2 .area.main .unit.flat .part,
  .cb-layout3 .area.main .unit.flat .part,
  .cb-layout2 .area.farwest .unit.flat .part,
  .cb-layout2 .fareast .unit.flat .part,
  .cb-layout2 .area.north .unit.flat .part,
  .cb-layout2 .area.main .unit.edge .part,
  .cb-layout3 .area.main .unit.edge .part,
  .cb-layout2 .area.farwest .unit.edge .part,
  .cb-layout2 .fareast .unit.edge .part,
  .cb-layout2 .area.north .unit.edge .part {
    margin-bottom: 20px;
  }
}
.cb-layout2 .area.main .unit.seam .part:first-child:not(.pict),
.cb-layout3 .area.main .unit.seam .part:first-child:not(.pict),
.cb-layout2 .area.farwest .unit.seam .part:first-child:not(.pict),
.cb-layout2 .fareast .unit.seam .part:first-child:not(.pict),
.cb-layout2 .area.north .unit.seam .part:first-child:not(.pict),
.cb-layout2 .area.main .unit.flat .part:first-child:not(.pict),
.cb-layout3 .area.main .unit.flat .part:first-child:not(.pict),
.cb-layout2 .area.farwest .unit.flat .part:first-child:not(.pict),
.cb-layout2 .fareast .unit.flat .part:first-child:not(.pict),
.cb-layout2 .area.north .unit.flat .part:first-child:not(.pict),
.cb-layout2 .area.main .unit.edge .part:first-child:not(.pict),
.cb-layout3 .area.main .unit.edge .part:first-child:not(.pict),
.cb-layout2 .area.farwest .unit.edge .part:first-child:not(.pict),
.cb-layout2 .fareast .unit.edge .part:first-child:not(.pict),
.cb-layout2 .area.north .unit.edge .part:first-child:not(.pict) {
  margin-top: 40px;
}
@media (max-width: 1199px) {
  .cb-layout2 .area.main .unit.seam .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.main .unit.flat .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.main .unit.edge .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.edge .part:first-child:not(.pict) {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .cb-layout2 .area.main .unit.seam .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.main .unit.flat .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.main .unit.edge .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.edge .part:first-child:not(.pict) {
    margin-top: 20px;
  }
}
.cb-layout2 .area.main .unit.seam .part.pict + .part,
.cb-layout3 .area.main .unit.seam .part.pict + .part,
.cb-layout2 .area.farwest .unit.seam .part.pict + .part,
.cb-layout2 .fareast .unit.seam .part.pict + .part,
.cb-layout2 .area.north .unit.seam .part.pict + .part,
.cb-layout2 .area.main .unit.flat .part.pict + .part,
.cb-layout3 .area.main .unit.flat .part.pict + .part,
.cb-layout2 .area.farwest .unit.flat .part.pict + .part,
.cb-layout2 .fareast .unit.flat .part.pict + .part,
.cb-layout2 .area.north .unit.flat .part.pict + .part,
.cb-layout2 .area.main .unit.edge .part.pict + .part,
.cb-layout3 .area.main .unit.edge .part.pict + .part,
.cb-layout2 .area.farwest .unit.edge .part.pict + .part,
.cb-layout2 .fareast .unit.edge .part.pict + .part,
.cb-layout2 .area.north .unit.edge .part.pict + .part {
  margin-top: 40px;
}
@media (max-width: 1199px) {
  .cb-layout2 .area.main .unit.seam .part.pict + .part,
  .cb-layout3 .area.main .unit.seam .part.pict + .part,
  .cb-layout2 .area.farwest .unit.seam .part.pict + .part,
  .cb-layout2 .fareast .unit.seam .part.pict + .part,
  .cb-layout2 .area.north .unit.seam .part.pict + .part,
  .cb-layout2 .area.main .unit.flat .part.pict + .part,
  .cb-layout3 .area.main .unit.flat .part.pict + .part,
  .cb-layout2 .area.farwest .unit.flat .part.pict + .part,
  .cb-layout2 .fareast .unit.flat .part.pict + .part,
  .cb-layout2 .area.north .unit.flat .part.pict + .part,
  .cb-layout2 .area.main .unit.edge .part.pict + .part,
  .cb-layout3 .area.main .unit.edge .part.pict + .part,
  .cb-layout2 .area.farwest .unit.edge .part.pict + .part,
  .cb-layout2 .fareast .unit.edge .part.pict + .part,
  .cb-layout2 .area.north .unit.edge .part.pict + .part {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .cb-layout2 .area.main .unit.seam .part.pict + .part,
  .cb-layout3 .area.main .unit.seam .part.pict + .part,
  .cb-layout2 .area.farwest .unit.seam .part.pict + .part,
  .cb-layout2 .fareast .unit.seam .part.pict + .part,
  .cb-layout2 .area.north .unit.seam .part.pict + .part,
  .cb-layout2 .area.main .unit.flat .part.pict + .part,
  .cb-layout3 .area.main .unit.flat .part.pict + .part,
  .cb-layout2 .area.farwest .unit.flat .part.pict + .part,
  .cb-layout2 .fareast .unit.flat .part.pict + .part,
  .cb-layout2 .area.north .unit.flat .part.pict + .part,
  .cb-layout2 .area.main .unit.edge .part.pict + .part,
  .cb-layout3 .area.main .unit.edge .part.pict + .part,
  .cb-layout2 .area.farwest .unit.edge .part.pict + .part,
  .cb-layout2 .fareast .unit.edge .part.pict + .part,
  .cb-layout2 .area.north .unit.edge .part.pict + .part {
    margin-top: 20px;
  }
}
.cb-layout2 .area.main .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout3 .area.main .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.farwest .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout2 .fareast .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.north .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.main .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout3 .area.main .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.farwest .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout2 .fareast .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.north .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.main .unit.edge .part:not(.pict) + .part:not(.pict),
.cb-layout3 .area.main .unit.edge .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.farwest .unit.edge .part:not(.pict) + .part:not(.pict),
.cb-layout2 .fareast .unit.edge .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.north .unit.edge .part:not(.pict) + .part:not(.pict) {
  margin-top: 0;
}
.cb-layout2 .area.main .unit.form.wide .part,
.cb-layout3 .area.main .unit.form.wide .part,
.cb-layout2 .area.farwest .unit.form.wide .part,
.cb-layout2 .fareast .unit.form.wide .part,
.cb-layout2 .area.north .unit.form.wide .part,
.cb-layout2 .area.main .unit.form.wide .foot,
.cb-layout3 .area.main .unit.form.wide .foot,
.cb-layout2 .area.farwest .unit.form.wide .foot,
.cb-layout2 .fareast .unit.form.wide .foot,
.cb-layout2 .area.north .unit.form.wide .foot {
  width: calc(100% - 30px);
  max-width: 772px;
  margin-left: 15px;
  margin-right: 15px%;
}
/*# sourceMappingURL=./screen-medium.css.map */